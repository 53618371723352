<template>
  <div class="wrap">
    <div class="top-banner">
      <div class="w-1200">
        <div class="title">机器学习平台</div>
        <div class="en">DU-LMP</div>
        <p>机器学习模型开发平台，围绕模型开发、模型服务、模型生命周期管理</p>
        <p>三个主题，构建统一的机器学习模型开发平台。</p>
      </div>
    </div>
    <div class="main-box w-1200">
      <div class="main-item">
        <div class="title">功能介绍</div>
        <div class="content">
          <div class="function-box">
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-1.png"/>
              <label>核心包</label>
              <p>模型持久化、模型文件加载等功能，包括模型训练、测试、预测的操作接口，包括具体模型开发需要实现的训练、测试、预测接口等。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-2.png"/>
              <label>模型开发</label>
              <p>实现相应的模型类接口，开发具体的模型，并对模型进行训练、测试、评估、调优等，最后产出模型文件，打标到模型版本服务统一管理。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-3.png"/>
              <label>模型服务模块</label>
              <p>提供模型生命周期管理相关的服务，包括模型上传、修改、部署（启动、重启）、重新加载、停用等服务。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-4.png"/>
              <label>共享模型池</label>
              <p>提供模型池统一管理模型生命周期。支持客户端分布式部署，因此支持部署多进程或分布式的模型服务，可提升预测服务性能，统一管理等。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-5.png"/>
              <label>共享日志模块</label>
              <p>独立进程模块，Soket通信，依赖核心包，需要输出日志到文件的模块都可以调用。共享日志模块，即服务端，提供统一打印、拆分日志文件功能。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-6.png"/>
              <label>模型版本服务模块</label>
              <p>提供模型版本管理相关服务，包括模型打标、查询、下载、删除、发布服务。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-7.png"/>
              <label>管理门户</label>
              <p>提供包括模型上传、修改、部署（启动、重启）、重新加载、停用等运维操作，可查看维护模型的基本信息、状态、修订日志、调用日志等。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="main-item">
        <div class="title">产品架构</div>
        <div class="content">
          <img src="~@/assets/images/lmp-architecture.jpg"/>
        </div>
      </div>
			<div class="main-item">
				<div class="title">模型封装</div>
				<div class="content">
					<img src="~@/assets/images/lmp-package.jpg"/>
				</div>
			</div>
      <div class="main-item">
        <div class="title">核心优势</div>
        <div class="content">
          <div class="core-box">
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-1.png"/>
              <div class="text-box">
                <label>简化工作流程</label>
                <p>简化机器学习开发和服务工作流程，结合大数据技术，一站式、方便快捷开发应用模型</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-2.png"/>
              <div class="text-box">
                <label>提高建模效率</label>
                <p>让模型开发工程师做专业的事，提高建模的效率</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-3.png"/>
              <div class="text-box">
                <label>快速引入业务场景</label>
                <p>快速将机器学习技术引入业务场景</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-4.png"/>
              <div class="text-box">
                <label>降低门槛</label>
                <p>快速将机器学习技术引入业务场景，降低机器学习的应用门槛</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="main-item">
        <div class="title">应用场景</div>
        <div class="content">
          <div class="scenario-box">
            <div class="scenario-item">
              <label>数据分析，面向企业精细化运营</label>
              <p>汇聚处理各类业务数据，基于中台强大的引擎能力完成复杂的分析与监控工作，灵活响应并满足新需求，实现对用户千人千面的精细化运营</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
            <div class="scenario-item">
              <label>数据治理，为企业数据保驾护航</label>
              <p>针对各种来源的数据，统一数据标准、统一数据资产，同时保障数据安全，从组织和个人角度，促进数据在组织内无障碍地进行交流</p>
              <img src="~@/assets/images/rdc/scenario-2.png"/>
            </div>
            <div class="scenario-item">
              <label>数据应用，助力企业数字化转型</label>
              <p>分析、管理数据，基于硬件和业务数据，完成企业的事前风险预测、事中监控调度和事后问题排查工作，保障生产和供应的可靠性</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #F7F8FA;
}
.top-banner {
  height: 320px;
  background-image: url('~@/assets/images/product/banner.png');
  background-repeat: no-repeat;
  background-position: center;
  .title {
    font-size: 40px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding-top: 82px;
  }
  .en {
    font-size: 40px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 100;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 14px;
  }
}
.main-box {
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #4F5969;
    padding: 40px 0;
  }
  .content {
    img {
      width: 1199px;
    }
  }
  .function-box {
    overflow: hidden;
    .function-item {
      box-sizing: border-box;
      float: left;
      width: 285px;
      height: 244px;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 30px;
      margin-right: 19px;
      >img {
        width: 26px;
      }
      &:nth-child(-n+4) {
        margin-bottom: 19px;
      }
      &:nth-child(4n){
        margin-right: 0;
      }
      label {
        display: block;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        color: #1D2129;
      }
      p {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        line-height: 1.2;
      }
    }
  }
  .core-box {
    overflow: hidden;
    .core-item {
      padding: 40px 28px 40px 30px;
      float: left;
      box-sizing: border-box;
      width: 590px;
      height: 159px;
      background: #FFFFFF;
      border-radius: 5px;
			margin-bottom: 20px;
      >img {
        width: 72px;
      }
      &:nth-child(2n-1) {
        margin-right: 20px;
      }
      img {
        float: left;
      }
      .text-box {
        margin-left: 102px;
        label {
          font-size: 24px;
          font-weight: 400;
          color: #1D2129;
        }
        p {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #87909C;
          line-height: 1.2;
        }
      }
    }
  }
  .scenario-box {
    overflow: hidden;
    margin-bottom: 60px;
    .scenario-item {
      padding-top: 30px;
      float: left;
      width: 386px;
      background: #FFFFFF;
      border-radius: 5px;
      >img {
        width: 386px;
      }
      &:not(:last-child){
        margin-right: 19px;
      }
      label {
        font-size: 20px;
        font-weight: 400;
        color: #1D2129;
        padding: 30px 30px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        margin-top: 22px;
        margin-bottom: 30px;
        padding: 0 30px;
        height: 77px;
        line-height: 1.2;
      }
    }
  }
}
</style>
